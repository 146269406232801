<template>
  <div class="workbench">
    <div class="dataEcharts">
      <div class="serviceData">
        <div class="data_echarts" ref="echarts2"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// 引入echarts
import * as echarts from 'echarts'
import { selectVisitRecordStatistics, selectVisitRecordDateStatistics } from '@/api/visiting.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 100,
  time: '', //头部；时间
  type: 1, //1=日，2=周，3=月，4=年
  reachStandard: '',
  unitId: '',
  weekNum: null
})

export default {
  props: {
    type: {
      type: Number
    },
    reachStandard: {
      type: String
    },
    time: {
      type: String
    },
    unit: {
      type: String
    },
    unitId: {
      type: String
    }
  },
  name: 'cockpit',
  data() {
    return {
      nameList: [],
      queryInfo: { ...defaultQueryInfo }
    }
  },
  computed: {},
  created() {},
  watch: {
    type(val) {
      this.queryInfo.type = val
      if (val == 2) {
        this.queryInfo.weekNum = this.getWeekNumber(new Date(this.queryInfo.time))
      } else if (val == 3) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        this.queryInfo.time = `${year}-${month}`
      } else if (val == 4) {
        const today = new Date(this.queryInfo.time)
        const year = today.getFullYear()

        this.queryInfo.time = `${year}`
      } else {
        this.queryInfo.time = this.time
      }
      if (this.unitId && val != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
    },
    time(val) {
      this.queryInfo.time = val
      if (this.type == 2) {
        this.queryInfo.weekNum = this.getWeekNumber(new Date(this.queryInfo.time))
      }
      if (this.unitId && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
      // this.search()
    },
    reachStandard(val) {
      this.queryInfo.reachStandard = val
      this.search()
    },
    unitId(val) {
      this.queryInfo.unitId = val
      if (val && this.type != 1) {
        this.selectVisit()
      } else {
        this.search()
      }
    }
  },
  mounted() {
    this.queryInfo.time = this.time
    this.queryInfo.type = this.type
    this.queryInfo.unitId = this.unitId
    this.queryInfo.reachStandard = this.reachStandard
    // this.search()
    if (this.type == 2) {
      this.queryInfo.weekNum = this.getWeekNumber(new Date(this.queryInfo.time))
    }
    if (this.unitId && this.type != 1) {
      this.selectVisit()
    } else {
      this.search()
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    //查询一览数据
    async search() {
      const res = await selectVisitRecordStatistics(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.nameList = []
        this.listData.forEach((obj) => {
          this.nameList.push(obj.unit)
        })
        this.renderEcharts2(70)
      }
    },
    //
    //获取当前年月日
    getTodayDate() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1 // 月份是从0开始的，所以需要+1
      const day = today.getDate()
      return `${year}-${month}-${day}`
    },
    // 计算第几周
    getWeekNumber(date) {
      // 获取一周的第一天（周日）的日期
      const firstDay =
        date.getDay() === 0
          ? date
          : new Date(date.getTime() - ((date.getDay() || 7) - 1) * 86400000)

      // 获取一年第一周的第一天（周日）的日期
      const firstWeekFirstDay = new Date(firstDay.getFullYear(), 0, 1)

      // 如果第一周的第一天不是周日，则将其调整为周日
      if (firstWeekFirstDay.getDay() !== 0) {
        firstWeekFirstDay.setDate(firstWeekFirstDay.getDate() - firstWeekFirstDay.getDay() + 1)
      }

      // 计算偏移量
      const offset = Math.floor((firstDay.getTime() - firstWeekFirstDay.getTime()) / (7 * 86400000))

      // 返回周数（可能为0，表示前一年的最后一周）
      return offset
    },
    async selectVisit() {
      const res = await selectVisitRecordDateStatistics(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.nameList = []
        this.listData.forEach((obj) => {
          this.nameList.push(obj.time)
        })
        var echarts2 = this.$refs.echarts2
        var myChart = echarts.init(echarts2)
        myChart.clear()
        if (this.queryInfo.type == 3) {
          this.renderEcharts2(60)
        } else {
          this.renderEcharts2(100)
        }
      }
    },
    renderEcharts2(end) {
      var echarts2 = this.$refs.echarts2
      var myChart = echarts.init(echarts2)
      let type = this.chart2
      let option
    
      // 所有的数据
      const chartArr = this.listData
      // const chart1Flag = this.chart1Flag
      let mymax = 0
      let myinterval = 0
      if (this.queryInfo.unitId) {
        if (this.queryInfo.type == 1) {
          mymax = 35
          myinterval = 5
        } else if (this.queryInfo.type == 3) {
            mymax = 35
            myinterval = 5
        } else if (this.queryInfo.type == 4) {
          mymax = 210
          myinterval = 30
        } else if (this.queryInfo.type == 2) {
            mymax = 35
            myinterval = 5
        }
      } else {
        if (this.queryInfo.type == 1) {
          mymax = 35
          myinterval = 5
        } else if (this.queryInfo.type == 3) {
          mymax = 140
          myinterval = 20
        } else if (this.queryInfo.type == 4) {
          mymax = 2100
          myinterval = 300
        } else if (this.queryInfo.type == 2) {
          mymax = 70
          myinterval = 10
        }
      }

      option = {
        // 可以显示整个图表
        dataZoom: [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: true,
            xAxisIndex: [0, 1],
            start: 10,
            end: 70
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 10,
            end: 70
          },
          {
            type: 'inside',
            xAxisIndex: 1,
            start: 10,
            end: 70
          }
        ],
        grid: {
          left: '5%', // 左边距
          right: '5%', // 右边距
          bottom: '20%' // 底部距离
        },

        color: '#f4b667',
        // 实现 echarts 鼠标悬浮上去的效果
        tooltip: {
          trigger: 'axis',
          // 是否显示提示框，默认为 true
          // trigger: 'item', // 触发类型，可选值: 'item'（数据项触发），'axis'（坐标轴触发），'none'（不触发）
          backgroundColor: '#fff', // 提示框背景色
          padding: [3], // 内边距
          textStyle: {
            // 文本样式
            color: '#fff', // 文本颜色
            fontSize: 12 // 文本字号
          },
          // 悬浮框提示
          formatter(params) {
            const userData = chartArr[params[0].dataIndex]
           
            return `
              <div style="min-width:145px; min-height:110px;  box-shadow: 0px 0px 2px 1px #f3f3f3; border-radius: 2px; border: 1px solid #f3f3f3; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px 7px; ">
             <div style=" display: flex;justify-content: center;margin-bottom:10px">
                      
                    <span style="font-size: 17px; color: #333; font-weight: 600;">${ userData.unit}</span>
                    
                  </div>
                <div style=" margin-bottom: 4px"display: flex; flex-direction: column; align-items: center;>
                   
                  <div style=" margin-left: 10px;">
                   
                    <span style="font-size: 15px; color: #333; font-weight: 600;">客户经理数</span>
                    <span style="font-size: 15px; color: #333;margin-left:5px;  font-weight: 400;margin-top:5px">${userData.customerNum}</span>
                  </div>
                   <div style=" margin-left: 10px;margin-top:10px">
                    <span style="font-size: 15px; color: #333; font-weight: 600;">走访次数</span>
                    <span style="font-size: 15px;margin-left:5px; color: #333; font-weight: 400;margin-top:5px;">${userData.visitNum}</span>
                  </div>
                   <div style=" margin-left: 10px;margin-top:10px"">
                    <span style="font-size: 15px; color: #333; font-weight: 600;">达标次数</span>
                    <span style="font-size: 15px; color: #333; margin-left:5px; font-weight: 400;margin-top:5px">${userData.eligibleVisitNum}</span>
                  </div>
                </div>
  
              </div>`

            //<img width="42" height="42" src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-584859660zf2.png" alt="" />
          },
          // 辅助线
          axisPointer: {
            type: 'line', // 指示器类型，可选值: 'line'（直线指示器），'shadow'（阴影指示器），'cross'（十字准星指示器）
            lineStyle: {
              color: '#1890FF', // 辅助线颜色
              type: 'dashed'
            },
            crossStyle: {
              // 十字准星指示器样式设置
              color: '#aaa' // 线条颜色
            },
            shadowStyle: {
              // 阴影指示器样式设置
              color: 'rgba(150,150,150,0.3)' // 阴影颜色
            }
          }
        },
        xAxis: [
          {
            type: 'category',

            axisLine: {
              //y轴线的配置
              show: true, //是否展示
              lineStyle: {
                color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                width: 1, //y轴线的宽度
                type: 'dashed' //y轴线为实线
              }
            },
            axisLabel: {
              //y轴文字的配置
              textStyle: {
                color: '#333333' //Y轴内容文字颜色
              },
              interval: 0 // 设置为0表示全部显示x轴坐标
            },
            // boundaryGap: false,
            nameTextStyle: {
              verticalAlign: 'bottom',
              lineHeight: 10,
              backgroundColor: '#DCE9FE'
            },

            data: this.nameList
          },
          {
            type: 'category',
            show: false,
            axisLine: {
              //y轴线的配置
              show: false, //是否展示
              lineStyle: {
                color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                width: 1, //y轴线的宽度
                type: 'dashed' //y轴线为实线
              }
            },
            axisLabel: {
              //y轴文字的配置
              textStyle: {
                color: '#333333' //Y轴内容文字颜色
              },
              interval: 0 // 设置为0表示全部显示x轴坐标
            },
            // boundaryGap: false,
            nameTextStyle: {
              verticalAlign: 'bottom',
              lineHeight: 10,
              backgroundColor: '#DCE9FE'
            },

            data: this.nameList
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '（次数/次）',
            position: 'left',
            nameTextStyle: {
              color: '#333', // 标题颜色
              fontSize: 15, // 标题字体大小
              fontWeight: 'bold' // 标题字体粗细，可以是 'normal'、'bold'、'bolder'、'lighter'、100 | 200 | 300 | 400...
              // 其他样式属性如 fontFamily, textBorderColor, textBorderWidth 等也可以在这里设置
            },
            axisLine: {
              //y轴线的配置
              show: true, //是否展示
              lineStyle: {
                color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                width: 1, //y轴线的宽度
                type: 'dashed' //y轴线为实线
              }
            },
            axisLabel: {
              //y轴文字的配置
              textStyle: {
                interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
                color: '#333333', //Y轴内容文字颜色
                fontSize: 10 // 设置y轴标题文字大小为25
              }
            },
            // y轴分割线
            splitLine: {
              show: true, // 显示分隔线
              lineStyle: {
                type: 'dashed', // 设置分隔线为虚线
                color: '#E4E4E4'
              }
            },
            min: '0',
            max: mymax,

            // 设置y轴分割段数
            // splitNumber: 6
            // 设置y轴刻度间隔，例如设置为1，则刻度间隔为1
            interval: myinterval
          },
          {
            type: 'value',
            name: '（人数/人）',
            nameTextStyle: {
              color: '#333', // 标题颜色
              fontSize: 15, // 标题字体大小
              fontWeight: 'bold' // 标题字体粗细，可以是 'normal'、'bold'、'bolder'、'lighter'、100 | 200 | 300 | 400...
              // 其他样式属性如 fontFamily, textBorderColor, textBorderWidth 等也可以在这里设置
            },
            axisLine: {
              //y轴线的配置
              show: true, //是否展示
              lineStyle: {
                color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                width: 1, //y轴线的宽度
                type: 'dashed' //y轴线为实线
              }
            },
            axisLabel: {
              //y轴文字的配置
              textStyle: {
                interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
                color: '#333333', //Y轴内容文字颜色
                fontSize: 10 // 设置y轴标题文字大小为25
              }
            },
            // y轴分割线
            splitLine: {
              show: true, // 显示分隔线
              lineStyle: {
                type: 'dashed', // 设置分隔线为虚线
                color: '#E4E4E4'
              }
            },
            min: 0,
            max: 35,
            // 设置y轴分割段数
            // splitNumber: 6
            // 设置y轴刻度间隔，例如设置为1，则刻度间隔为1
            interval: 5
          }
        ],

        series: [
          {
            name: '客户',
            data: this.listData.map((item) => item.customerNum),

            label: {
              show: true, // 显示数值

              position: 'top' // 在顶部显示
            },
            type: 'bar',
            barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
            barMaxWidth: '25px', // 限制柱子的最大宽度
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                [
                  { offset: 0, color: '#3e86ff' }, // 0% 处的颜色
                  { offset: 1, color: '#a4c6ff' } // 100% 处的颜色
                ]
              )
            },
            // 面积颜色
            areaStyle: {
              color: '#DCE9FE'
            },
            markPoint: {
              symbol: 'circle' // 设置拐点小圆点
              // 其他配置项...
            },

            yAxisIndex: 1,
            xAxisIndex: 0,
            symbolSize: 0 // 设置拐点小圆点大小
          },
          {
            name: '客户',
            data: this.listData.map((item) => item.customerNum),

            label: {
              show: true, // 显示数值

              position: 'top' // 在顶部显示
            },
            type: 'bar',
            barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
            barMaxWidth: '25px', // 限制柱子的最大宽度
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                [
                  { offset: 0, color: '#3e86ff' }, // 0% 处的颜色
                  { offset: 1, color: '#a4c6ff' } // 100% 处的颜色
                ]
              )
            },
            // 面积颜色
            areaStyle: {
              color: '#DCE9FE'
            },
            markPoint: {
              symbol: 'circle' // 设置拐点小圆点
              // 其他配置项...
            },

            yAxisIndex: 1,
            xAxisIndex: 1,
            symbolSize: 0 // 设置拐点小圆点大小
          },
          {
            name: '走访次数',
            data: this.listData.map((item) => item.visitNum),
            label: {
              show: true, // 显示数值

              position: 'top', // 在顶部显示
              color: '#333333'
            },

            type: 'bar',
            barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
            barMaxWidth: '25px', // 限制柱子的最大宽度
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                [
                  { offset: 0, color: '#ff3a3a' }, // 0% 处的颜色
                  { offset: 1, color: '#ff9292' } // 100% 处的颜色
                ]
              )
            },
            // 面积颜色
            areaStyle: {
              color: '#DCE9FE'
            },
            markPoint: {
              symbol: 'circle' // 设置拐点小圆点
              // 其他配置项...
            },

            z: 9,
            yAxisIndex: 0,
            xAxisIndex: 0,
            symbolSize: 0 // 设置拐点小圆点大小
          },
          {
            name: '达标次数',
            data: this.listData.map((item) => item.eligibleVisitNum),
            label: {
              show: true, // 显示数值
              color: 'red',
              position: 'top' // 在顶部显示
            },
            type: 'bar',

            barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
            barMaxWidth: '25px', // 限制柱子的最大宽度
            itemStyle: {
              color: '#ffe3e3'
            },
            // 面积颜色
            areaStyle: {
              color: '#DCE9FE'
            },
            markPoint: {
              symbol: 'circle' // 设置拐点小圆点
              // 其他配置项...
            },
            z: 0,
            // stack: 'total',
            yAxisIndex: 0,
            xAxisIndex: 1,
            symbolSize: 0 // 设置拐点小圆点大小
          }
        ]
      }

      option && myChart.setOption(option)

      window.addEventListener('resize', function () {
        myChart.resize()
      })
    }
  }
}
</script>
  
  <style lang="less" scoped>
.active {
  border: 1px 0px 0px 0px;
  background: #85b6ff;
  color: #fff;
  cursor: pointer;

}
.workbench {
  height: 100%;
  background: #f6f8f9;
  box-sizing: border-box;
  overflow-y: auto;
  margin-left: 8px;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }

  .dataEcharts {
    height: 100%;
    width: 100%;

    .serviceData {
      border-radius: 8px;
      height: calc(100% - 40px);
      padding: 20px 20px 10px 20px;
      background: #fff;

      .data_echarts {
        height: 500px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}
</style>
  